<div style="margin: 16px 2px 2px 2px">
    <label>{{ label }}</label>
    <div (click)="showImagePicker()" style="cursor: pointer; border-radius: 4px; width: 100px; height: 100px">
        <div class="image-selection">
            <div *ngIf="imgSrc$ | async as imgSrc">
                <img [src]="imgSrc" style="object-fit: cover; width: 100%; height: 100%" />
            </div>
            <div *ngIf="(imgSrc$ | async) === undefined" class="image-search">
                <mat-icon style="color: #a9afbb" color="primary">image_search</mat-icon>
            </div>

            <ngx-skeleton-loader
                *ngIf="(imgSrc$ | async) === null"
                style="display: block"
                animation="progress"
                appearance="line"
                [theme]="{ height: '98px', width: '98px' }"
            >
            </ngx-skeleton-loader>
        </div>
    </div>
</div>
