import { AsyncPipe, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { BehaviorSubject, map, takeUntil } from 'rxjs'
import { ParseService } from 'src/app/parse.service'
import { ImagePickerData, ImagePickerDialogComponent } from 'src/app/shared/image-picker-dialog/image-picker-dialog.component'
import { Objects } from '../../../../../server/src/shared-with-client/object-definitions'
import { MlsListingImageItem } from '../image-picker-dialog/image-picker-dialog.component'

@Component({
    selector: 'app-image-selection-placeholder',
    templateUrl: './image-selection-placeholder.component.html',
    styleUrls: ['./image-selection-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MatIconModule, NgxSkeletonLoaderModule, AsyncPipe],
})
export class ImageSelectionPlaceholderComponent {
    @Input({ required: true }) get image() {
        return this.image$.value
    }

    set image(image: Objects.Image.Parse | undefined | null) {
        this.image$.next(image)
    }
    image$ = new BehaviorSubject<Objects.Image.Parse | undefined | null>(null)

    @Input({ required: true }) label: 'Headshot' | 'Company Logo' | 'Brokerage Logo' | undefined
    @Input() category: 'Agent' | 'Branding' | undefined
    @Input() subcategory: 'headshot' | 'companyLogo' | 'brokerageLogo' | undefined

    @Output() imageChange = new EventEmitter<Objects.Image.Parse>()

    imgSrc$ = this.image$.pipe(
        map((image) => {
            if (!image) return image
            return image?.get('thumbURL') ?? image?.get('url')
        })
    )

    constructor(
        private parseService: ParseService,
        private dialog: MatDialog
    ) {}

    isParseImage(image: Objects.Image.Parse | MlsListingImageItem): image is Objects.Image.Parse {
        const IMAGE_COLLECTION: Objects.Image.Collection = 'Image'
        return 'className' in image && image.className === IMAGE_COLLECTION
    }

    showImagePicker() {
        const establishmentId = this.parseService.selectedEstablishment.value?.id
        if (!establishmentId) {
            return
        }

        if (this.label === undefined) return

        const data: ImagePickerData = {
            name: this.label,
            category: this.category,
            subcategory: this.subcategory,
            establishmentId,
            mlsListing: undefined,
            photoKey: undefined,
        }

        const dialog = this.dialog.open(ImagePickerDialogComponent, { data, panelClass: 'image-dialog-container' })
        dialog.componentInstance.imageSelected.pipe(takeUntil(dialog.afterClosed())).subscribe(({ image }) => {
            if (this.isParseImage(image)) {
                this.imageChange.emit(image)
            }
        })
    }
}
