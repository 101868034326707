import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Inject, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ChiliColorAll } from '../../types'
import { AbstractDialogComponent } from '../dialog.class'

export interface Data {
    initialColorHex: string | undefined
}

@Component({
    selector: 'app-chili-color-picker-dialog',
    template: `
        <mat-dialog-content>
            <app-chili-color-picker [initialColorHex]="data.initialColorHex" (colorSelected)="onColorSelected($event)"></app-chili-color-picker>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-flat-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ColorPickerDialogComponent extends AbstractDialogComponent<Data, ColorPickerDialogComponent> {
    @HostBinding('class') classes = 'mat-typography'

    @Output() colorSelected = new EventEmitter<ChiliColorAll>()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Data,
        public dialogRef: MatDialogRef<ColorPickerDialogComponent>
    ) {
        super(data, dialogRef)
    }

    onColorSelected(ev: ChiliColorAll) {
        this.colorSelected.emit(ev)
    }
}
